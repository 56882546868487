<template>
  <div style="position: relative" class="bigName" >
    <el-tooltip
      class="item"
      effect="dark"
      :content="$attrs.value"
      placement="top"
      :disabled="showTooltip"
    >
      <span class="name" @click="jump">{{ $attrs.value }}</span>
    </el-tooltip>
    <icon-button
      content="查看详情"
      icon="iconfont iconchakan"
      @click="jump"
      class="baseBtn"
    />
  </div>
</template>

<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  components: { IconButton },
  props: {},
  data () {
    return {
      showTooltip: true
    }
  },
  // 计算属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    '$attrs.value': {
      handler (val) {
        this.watchWindowSize()
      }
    }
  },
  // 方法集合
  methods: {
    watchWindowSize () {
      this.$nextTick(() => {
        const name = document.getElementsByClassName('name')[0]
        this.showTooltip = name.offsetWidth >= name.scrollWidth
      })
    },
    jump () {
      if (this.$attrs.enterpriseLabel === '供应商') {
        this.$router.push({ path: '/CRM/enterpriseInformation', query: { keyId: this.$route.query.keyId } })
      } else {
        this.$router.push({
          path: '/addProjectCompany',
          query: {
            keyId: this.$route.query.keyId,
            type: 'details',
            jump: 'interview'
          }
        })
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    window.addEventListener('resize', this.watchWindowSize)
  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {
    window.removeEventListener('resize', this.watchWindowSize)
  }, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.bigName{
  display: flex;
  align-items: center;
  height: 15.7px;
}
.name {
  cursor: pointer;
  color: #2862E7;
  text-decoration: underline;
  display: block;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.baseBtn {
  // position: absolute;
  // top: -6px;
  // left: 166px;
  margin-left:10px ;
}
</style>
